import React from 'react'
import styled from 'styled-components'

const Logo = styled.svg`
  width: ${props => props.width ? props.width : '200px' };
  height: auto;
  .cls-1, .cls-2 {
        fill: ${props => props.fill ? props.fill : '#e31837' };
      }

      .cls-1 {
        fill-rule: evenodd;
      }
`
const MadshusLogo = (props) => {
  return (
    <div>
      <a href="https://madshus.com/" target="_blank" rel="noopener noreferrer">
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.85199 83.9048" {...props}>
  <title>madshus-logo</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Foreground">
      <g>
        <polygon class="cls-1" points="0 83.905 11.168 0 21.24 32.741 31.312 0 42.48 83.905 21.24 42.111 0 83.905"/>
        <polygon class="cls-1" points="53.503 62.204 69.966 14.352 83.743 14.352 81.051 43.225 98.117 14.838 112.386 14.838 96.407 62.202 85.878 62.203 96.522 32.194 79.186 62.203 71.216 62.204 73.519 33.655 63.934 62.204 53.503 62.204"/>
        <path class="cls-1" d="M124.103,40.1421l6.2979-.001,1.3667-13.1387ZM102.665,53.4428l26.543-38.9301,12.2021-.0005L139.665,53.4419H129.3325l.2207-4.7032H118.9258l-2.7793,4.7041Z"/>
        <path class="cls-1" d="M157.4463,42.229a38.81948,38.81948,0,0,0,5.2617-.1621,4.68939,4.68939,0,0,0,1.6167-.4873,6.59488,6.59488,0,0,0,1.3144-.8116,8.22425,8.22425,0,0,0,1.2149-1.0537,9.97976,9.97976,0,0,0,1.2305-1.541,13.28044,13.28044,0,0,0,1.0273-1.7842,14.75138,14.75138,0,0,0,.9492-2.271,14.66237,14.66237,0,0,0,.8281-3.6494,6.30236,6.30236,0,0,0-.0097-2.1089,5.82443,5.82443,0,0,0-.4395-1.3789,2.14929,2.14929,0,0,0-.6543-.9731,2.21481,2.21481,0,0,0-1.3398-.73c-.6406-.0405-1.4571-.1074-2.0196-.081-1.7207.0815-1.3632.0395-3.1972.0815ZM143.1592,53.5024l13.1601-38.9297c5.8047-.081,8.8062-.081,14.7612-.0005a10.24144,10.24144,0,0,1,4.3457,1.0542,8.316,8.316,0,0,1,3.3809,2.271,9.05058,9.05058,0,0,1,1.90039,3.3658,14.48916,14.48916,0,0,1,.8828,4.5009,20.83852,20.83852,0,0,1-.0351,4.0552,24.229,24.229,0,0,1-1.375,6.3262,27.39211,27.39211,0,0,1-2.4727,5.5151,23.98618,23.98618,0,0,1-2.59179,4.0547,17.33468,17.33468,0,0,1-3.0996,3.082,17.8444,17.8444,0,0,1-3.5078,2.2705,18.40154,18.40154,0,0,1-4.0078,1.6221,14.34485,14.34485,0,0,1-4.711.8115Z"/>
        <path class="cls-1" d="M215.41059,14.5102l-3.6699,10.7056-15.7441.0005,8.2988,8.2724a16.66617,16.66617,0,0,1,2.7109,3.4058,10.27071,10.27071,0,0,1,1.1621,5.0278c.1602,5.4161-1.94329,11.8243-6.9941,12.0039l-23.0254.001,3.8594-11.0303h17.6152c-3.2031-3.4072-4.998-4.8662-8.9961-8.7592a12.12822,12.12822,0,0,1-3.98829-9.5699c.03909-5.916,2.77929-10.0566,6.23639-10.0571Z"/>
        <polygon class="cls-1" points="223.676 14.51 233.811 14.509 229.784 27.324 239.821 27.324 244.149 14.834 254.481 14.834 241.323 53.763 230.891 53.763 235.766 38.516 225.829 38.516 220.75 53.439 210.418 53.439 223.676 14.51"/>
        <path class="cls-1" d="M261.36569,14.5088l10.2832-.0005-8.0391,23.6821c-.9023,5.1909,2.0469,4.5767,3.9122,4.7036,2.3652.1621,4.1308-.1621,5.74411-3.8926l8.57229-24.1689,10.0371-.0005L282.3891,43.3803c-3.79491,8.9219-7.81051,10.5987-16.93941,11.5176-8.05859.8115-15.3262-4.542-13.2031-13.4629Z"/>
        <path class="cls-1" d="M323.852,14.5068,320.1821,25.2124l-15.74411.0005,8.2988,8.2724a16.653,16.653,0,0,1,2.7109,3.4063,10.26943,10.26943,0,0,1,1.1621,5.0288c.1602,5.415-1.9433,11.8232-6.9941,12.0019l-23.0254.001,3.8594-11.0303h17.6152c-3.2031-3.4062-4.998-4.8657-8.9961-8.7587a12.12826,12.12826,0,0,1-3.9883-9.5699c.0391-5.9155,2.7793-10.0566,6.2364-10.0571Z"/>
        <g>
          <path class="cls-2" d="M119.9936,67.896h-2.02v-.961h5.2481v.961h-2.0201v5.2744h-1.208Z"/>
          <path class="cls-2" d="M124.1904,66.935h1.1992v2.6368h2.9458V66.935h1.1997v6.2354h-1.1997V70.5073h-2.9458v2.6631h-1.1992Z"/>
          <path class="cls-2" d="M131.2016,66.935h3.8628v.9522h-2.6635v1.7021h2.4697v.8994h-2.4697v1.7295h2.8667v.9522h-4.066Z"/>
          <path class="cls-2" d="M138.1821,70.0923c0-2.0899,1.1553-3.2452,3.334-3.2452a4.34823,4.34823,0,0,1,1.8872.3526l-.3174.8994a4.87881,4.87881,0,0,0-1.5522-.2471,1.94689,1.94689,0,0,0-2.1167,2.1524,2.08744,2.08744,0,0,0,2.2929,2.249,4.91772,4.91772,0,0,0,1.4551-.2031l.2647.9258a5.1402,5.1402,0,0,1-1.8785.291C139.355,73.2671,138.1821,72.0678,138.1821,70.0923Z"/>
          <path class="cls-2" d="M147.6494,72.0151l-.8731-1.1465a3.352,3.352,0,0,1-.4321.0264h-.6089v2.2754h-1.208V66.935h2.2754c1.3228,0,2.2578.6172,2.2578,1.958a1.80426,1.80426,0,0,1-1.0674,1.6934l.7676.9522a2.34226,2.34226,0,0,0,1.3316.8115l-.2647.9258A2.91773,2.91773,0,0,1,147.6494,72.0151Zm-1.2524-2.0019c.9169,0,1.4199-.3438,1.4199-1.0762,0-.7588-.3531-1.0498-1.2085-1.0498h-.8731v2.126Z"/>
          <path class="cls-2" d="M150.5786,70.0395a3.06044,3.06044,0,0,1,3.3516-3.2011,2.92347,2.92347,0,0,1,3.166,3.2187,3.01657,3.01657,0,0,1-3.3335,3.2188A2.93234,2.93234,0,0,1,150.5786,70.0395Zm5.2915.0088a2.04633,2.04633,0,1,0-4.0742-.0176c0,1.2432.6084,2.2842,1.9931,2.2842C155.2705,72.3149,155.8701,71.3266,155.8701,70.0483Z"/>
          <path class="cls-2" d="M157.8657,72.853l.353-.8906a3.91953,3.91953,0,0,0,1.5786.2998c.732,0,1.2525-.2295,1.2525-.7764,0-1.2168-2.9986-.9082-2.9986-2.999,0-1.1289,1.0318-1.6397,2.0904-1.6397a3.87376,3.87376,0,0,1,1.7812.379l-.3437.8554a4.16343,4.16343,0,0,0-1.4024-.2558c-.644,0-.9526.247-.9526.6435,0,1.0498,3.0337.9795,3.0337,2.9639,0,1.3047-1.1816,1.834-2.4429,1.834A3.826,3.826,0,0,1,157.8657,72.853Z"/>
          <path class="cls-2" d="M162.9868,72.853l.3535-.8906a3.91876,3.91876,0,0,0,1.5781.2998c.7325,0,1.252-.2295,1.252-.7764,0-1.2168-2.9981-.9082-2.9981-2.999,0-1.1289,1.0332-1.6397,2.0899-1.6397a3.88334,3.88334,0,0,1,1.7832.379l-.3457.8554a4.14933,4.14933,0,0,0-1.4024-.2558c-.6425,0-.9511.247-.9511.6435,0,1.0498,3.0332.9795,3.0332,2.9639,0,1.3047-1.1817,1.834-2.4434,1.834A3.82878,3.82878,0,0,1,162.9868,72.853Z"/>
          <path class="cls-2" d="M170.268,70.0923c0-2.0899,1.1563-3.2452,3.334-3.2452a4.34322,4.34322,0,0,1,1.8867.3526l-.3164.8994a4.88946,4.88946,0,0,0-1.5527-.2471,1.947,1.947,0,0,0-2.1172,2.1524,2.0877,2.0877,0,0,0,2.293,2.249,4.91074,4.91074,0,0,0,1.4551-.2031l.2656.9258a5.149,5.149,0,0,1-1.8789.291C171.4419,73.2671,170.268,72.0678,170.268,70.0923Z"/>
          <path class="cls-2" d="M176.1235,70.0395a3.06019,3.06019,0,0,1,3.3516-3.2011,2.92324,2.92324,0,0,1,3.166,3.2187,3.01673,3.01673,0,0,1-3.334,3.2188A2.93224,2.93224,0,0,1,176.1235,70.0395Zm5.291.0088a2.04633,2.04633,0,1,0-4.0742-.0176c0,1.2432.6094,2.2842,1.9942,2.2842C180.81489,72.3149,181.41449,71.3266,181.41449,70.0483Z"/>
          <path class="cls-2" d="M183.897,70.7719V66.935h1.2246v3.7832a1.37574,1.37574,0,0,0,1.5254,1.5616c.95311,0,1.53511-.503,1.53511-1.7549V66.935h1.18159v3.6162a2.49569,2.49569,0,0,1-2.7167,2.7334C185.29929,73.2846,183.897,72.7114,183.897,70.7719Z"/>
          <path class="cls-2" d="M190.979,66.935h1.4726l2.7246,4.7891-.03509-.7324V66.935h1.209v6.2354h-1.54491L192.143,68.478l.0352.7588v3.9336H190.979Z"/>
          <path class="cls-2" d="M199.33639,67.896h-2.0195v-.961h5.248v.961h-2.0195v5.2744h-1.209Z"/>
          <path class="cls-2" d="M206.65669,72.0151l-.873-1.1465a3.37581,3.37581,0,0,1-.43359.0264h-.60741v2.2754h-1.209V66.935h2.27541c1.32419,0,2.25779.6172,2.25779,1.958a1.80268,1.80268,0,0,1-1.0664,1.6934l.7675.9522a2.34059,2.34059,0,0,0,1.33211.8115l-.26561.9258A2.91515,2.91515,0,0,1,206.65669,72.0151Zm-1.2539-2.0019c.918,0,1.4219-.3438,1.4219-1.0762,0-.7588-.3535-1.0498-1.209-1.0498h-.873v2.126Z"/>
          <path class="cls-2" d="M211.07659,71.0268l-2.1972-4.0918h1.38471l1.46489,3.0694,1.5605-3.0694h1.3399l-2.3457,4.0655v2.1699h-1.2071Z"/>
          <path class="cls-2" d="M217.1391,70.0923c0-2.0899,1.15629-3.2452,3.334-3.2452a4.34316,4.34316,0,0,1,1.88669.3526l-.3164.8994a4.88946,4.88946,0,0,0-1.5527-.2471,1.947,1.947,0,0,0-2.1172,2.1524,2.0877,2.0877,0,0,0,2.293,2.249,4.91069,4.91069,0,0,0,1.4551-.2031l.2656.9258a5.149,5.149,0,0,1-1.8789.291C218.313,73.2671,217.1391,72.0678,217.1391,70.0923Z"/>
          <path class="cls-2" d="M222.99459,70.0395a3.06019,3.06019,0,0,1,3.3516-3.2011,2.92323,2.92323,0,0,1,3.166,3.2187,3.01673,3.01673,0,0,1-3.334,3.2188A2.93224,2.93224,0,0,1,222.99459,70.0395Zm5.291.0088a2.04633,2.04633,0,1,0-4.0742-.0176c0,1.2432.6094,2.2842,1.9941,2.2842C227.686,72.3149,228.28559,71.3266,228.28559,70.0483Z"/>
          <path class="cls-2" d="M230.83249,66.935h1.5352l1.8515,5.2383,2.1074-5.2383h1.4746v6.2354h-1.2089V70.0395l.0351-1.2793-1.7207,4.4102h-1.4375l-1.5156-4.4717.0781,1.3408v3.1309h-1.1992Z"/>
          <path class="cls-2" d="M239.47119,66.935h2.2754a2.03591,2.03591,0,0,1,2.2578,2.1162c0,1.1827-.918,2.17-2.7168,2.17h-.4239v-.9434h.4766c.9531,0,1.4199-.459,1.4199-1.1904a1.05745,1.05745,0,0,0-1.209-1.2002h-.873v5.2832h-1.207Z"/>
          <path class="cls-2" d="M246.44189,66.935h1.5605l2.1602,6.2354h-1.252l-.68749-2.0195h-2.24021l-.7676,2.0195h-1.209Zm1.49021,3.3252-.75781-2.5576-.8555,2.5576Z"/>
          <path class="cls-2" d="M251.15869,66.935h1.4726l2.7266,4.7891-.0352-.7324V66.935h1.2071v6.2354h-1.543l-2.6641-4.6924.0352.7588v3.9336h-1.1992Z"/>
          <path class="cls-2" d="M259.67619,71.0268,257.479,66.935h1.3847l1.4649,3.0694,1.56051-3.0694H263.229l-2.3457,4.0655v2.1699h-1.2071Z"/>
          <path class="cls-2" d="M265.65089,72.853l.3515-.8906a3.92859,3.92859,0,0,0,1.5801.2998c.7324,0,1.252-.2295,1.252-.7764,0-1.2168-2.9981-.9082-2.9981-2.999,0-1.1289,1.0313-1.6397,2.0898-1.6397a3.8804,3.8804,0,0,1,1.7813.379l-.3438.8554a4.15793,4.15793,0,0,0-1.4023-.2558c-.6445,0-.9531.247-.9531.6435,0,1.0498,3.0351.9795,3.0351,2.9639,0,1.3047-1.1816,1.834-2.44329,1.834A3.82879,3.82879,0,0,1,265.65089,72.853Z"/>
          <path class="cls-2" d="M271.30519,66.935h1.1992v6.2354h-1.1992Z"/>
          <path class="cls-2" d="M274.15869,66.935h1.4726l2.7246,4.7891-.0351-.7324V66.935h1.209v6.2354h-1.545l-2.6621-4.6924.0352.7588v3.9336h-1.1992Z"/>
          <path class="cls-2" d="M280.84619,70.0923c0-2.0899,1.1543-3.2452,3.332-3.2452a4.35467,4.35467,0,0,1,1.8887.3526l-.3184.8994a4.87,4.87,0,0,0-1.5527-.2471,1.9465,1.9465,0,0,0-2.1153,2.1524,2.08684,2.08684,0,0,0,2.293,2.249,4.92017,4.92017,0,0,0,1.4551-.2031l.2637.9258a5.13352,5.13352,0,0,1-1.8789.291C282.018,73.2671,280.84619,72.0678,280.84619,70.0923Z"/>
          <path class="cls-2" d="M287.19189,66.935h3.8633v.9522H288.3911v1.7021h2.46869v.8994H288.3911v1.7295h2.86519v.9522h-4.0644Z"/>
          <path class="cls-2" d="M294.34029,72.2094h1.4805V67.9136H294.3481v-.917l2.64649-.0704v5.2832h1.4824v.961h-4.1367Z"/>
          <path class="cls-2" d="M299.71529,73.0825l.2559-.9268a3.718,3.718,0,0,0,.916.1153c1.4121,0,1.7461-1.1114,1.7461-2.7168,0-1.1553-.3164-1.7285-1.19919-1.7285a.96107.96107,0,0,0-.96881,1.0761c0,.8731.4317,1.1201,1.04881,1.1201a3.02091,3.02091,0,0,0,.918-.1416l-.01951.8916a2.96379,2.96379,0,0,1-1.0313.167c-1.3047,0-2.0996-.5644-2.0996-1.958a2.05015,2.05015,0,0,1,2.2149-2.125c1.455,0,2.3183.8994,2.3183,2.8047,0,2.5127-1.1015,3.6065-2.9453,3.6065A3.55944,3.55944,0,0,1,299.71529,73.0825Z"/>
          <path class="cls-2" d="M304.79929,70.145c0-1.6309.3965-3.2627,2.42581-3.2627,2.01949,0,2.26559,1.6406,2.26559,3.0869,0,1.8516-.5371,3.2803-2.416,3.2803C305.17819,73.2495,304.79929,71.812,304.79929,70.145Zm3.4922-.1318c0-1.208-.1328-2.1524-1.1192-2.1524-.98819,0-1.1738.9795-1.1738,2.2578,0,1.3848.2735,2.1524,1.1192,2.1524C308.04539,72.271,308.28169,71.4594,308.29149,70.0132Z"/>
          <path class="cls-2" d="M310.438,70.48c0-2.4161,1.1562-3.6065,2.9023-3.6065a3.63385,3.63385,0,0,1,1.1817.1758l-.25589.918a3.965,3.965,0,0,0-.93361-.1065c-1.3066,0-1.7109,1.1377-1.7109,2.6729,0,1.2695.2734,1.7812,1.207,1.7812a.99981.99981,0,0,0,.9707-1.1201.96945.96945,0,0,0-1.0586-1.1113,3.08545,3.08545,0,0,0-.9004.1416l.0176-.9004a2.92688,2.92688,0,0,1,.998-.1582c1.3496,0,2.1153.6347,2.1153,1.9404a2.066,2.066,0,0,1-2.2032,2.169C311.28559,73.2759,310.438,72.5005,310.438,70.48Z"/>
        </g>
      </g>
    </g>
  </g>
</Logo>
</a>
    </div>
  )
}

export default MadshusLogo
