import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"

// import '@fortawesome/fontawesome-svg-core/styles.css';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import {
//   faInstagramSquare,
//   faFacebookSquare,
//   faTwitterSquare,
//   faYoutubeSquare,
//   faLinkedin
// } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <>
      <footer>
        <Container fluid className="my-4 text-center">
            <Row>
                <Col>
                </Col>
            </Row>
          © {new Date().getFullYear()}, Teton Ridge Classic | Built by
          {` `}
          <a href="https://inlightmedia.com">Inlight Media</a>
        </Container>
      </footer>
    </>
  )
}

export default Footer
