import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav, Col, Row } from "react-bootstrap"
import TRCLogo from "../../images/TRCLogo"
import MadshusLogo from "../../images/madshus-logo"

const NavBar = () => (
  <Container fluid className="header-container">
    <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="navbar-container"
        >
      <Link to="/">
        <Navbar.Brand className="brand">
          <TRCLogo />
          {/* <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div><TRCLogo /></div>
            <div>
              <p style={{
                fontSize: '0.8rem',
                textAlign: 'center',
                marginBottom: 0,
                marginTop: '10px'
              }}>Presented By</p>
              <MadshusLogo 
                fill="white"
              />
            </div>
          </div> */}

        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav justify-content-center">
        <Nav
          className="mr-auto"
          style={{
            width: "100%",
            justifyContent: 'space-around'
          }}
        >
          <Link to="/info" activeClassName="active" className="navbar-link">
            <Nav.Link as="span">Information</Nav.Link>
          </Link>
          <Link to="/registration" activeClassName="active" className="navbar-link">
            <Nav.Link as="span">Registration</Nav.Link>
          </Link>
          <Link to="/results" activeClassName="active" className="navbar-link">
            <Nav.Link as="span">Results</Nav.Link>
          </Link>
          <Link to="/maps" activeClassName="active" className="navbar-link">
            <Nav.Link as="span">Maps</Nav.Link>
          </Link>
          <Link to="/contact" activeClassName="active" className="navbar-link">
            <Nav.Link as="span">Contact Us</Nav.Link>
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Container>
)

export default NavBar
